import { ReactElement } from "react";
import { defaultLimits, round } from "helpers";
import { models } from "../../../helpers/constructorModels";
import { trailers } from "helpers/constructorTrailers";
import { IDesign } from "helpers/constructorTypes";
import { ISettings } from "redux/settings/settings_types";
import { TabIds } from "types/other";

const SensorsWModel = ({
  sensors,
  isPreview = false,
  design,
  isFlat,
  limit = defaultLimits,
  tab = TabIds.Bar,
}: {
  sensors: [] | [[number, number]];
  isPreview?: boolean;
  design: IDesign;
  isFlat?: boolean;
  tab?: string;
  limit?: ISettings;
}) => {
  const { modelId, trailerId, positions } = design;

  if (!modelId) return <div>Пустой конструктор</div>;

  const modelAxes = [...models[modelId].axes].reverse();
  const trailerAxes = trailerId !== null ? [...trailers[trailerId].axes].reverse() : [];

  //////////////////////////////////////////////////////////////////////////////////////////
  const getWheelByIndex = (index: number): { content: ReactElement; classes: string[] } => {
    const classes: string[] = ["wheel"];

    if (positions.indexOf(index) === -1) {
      classes.push("wheel--empty");
      return { content: <div className="wheel-value"></div>, classes };
    }

    classes.push("wheel--filled");

    if (isFlat) {
      return {
        content: <div className="wheel-value">{(positions.indexOf(index) + 1).toString()}</div>,
        classes,
      };
    }

    const sensor = sensors[positions.indexOf(index)];

    let value: number;
    let content: ReactElement;

    switch (tab) {
      case TabIds.Temp:
        value = sensor[1];
        content = <div className="wheel-value">{round(value)}°C</div>;

        if (value > limit.temp_max) {
          classes.push("wheel--yellow");
        }

        break;
      case TabIds.TempLimit:
        value = sensor[1];
        content = <div className="wheel-value">{round(limit.temp_max)}°C</div>;

        if (value > limit.temp_max) {
          classes.push("wheel--yellow");
        }

        break;
      case TabIds.BarLimits:
        value = sensor[0];
        content = (
          <div className="wheel__limits">
            <span className="wheel__limits-value">{round(limit.bar_min)}</span>
            <span className="wheel__limits-value">{round(limit.bar_max)}</span>
          </div>
        );

        if ((value > 0 && value < limit.bar_min) || value > limit.bar_max) {
          classes.push("wheel--red");
        }

        break;
      default:
        // давление
        value = sensor[0];

        content = <div className="wheel-value">{round(value)}</div>;
        if ((value > 0 && value < limit.bar_min) || value > limit.bar_max) {
          classes.push("wheel--red");
        }

        break;
    }

    return { content, classes };
  };
  //////////////////////////////////////////////////////////////////////////////////////////

  let wheelIndex = 0;

  const axesListModel = modelAxes.map((axis, axisIndex) => {
    const wheels = axis.wheels.map((offset) => {
      wheelIndex++;
      const wIndex = wheelIndex;
      const item = getWheelByIndex(wIndex);

      const classes = item.classes;
      if (axis.mini) classes.push("wheel--mini");

      return (
        <div
          className="wheel-container"
          style={{ transform: `translate(0, ${offset}px)` }}
          key={wIndex}
        >
          <div className={item.classes.join(" ")}>
            {/* [{wIndex}] */}
            {item.content}
          </div>
        </div>
      );
    });

    return (
      <div className="axis" key={axisIndex} style={{ transform: `translate(${axis.offset}px, 0)` }}>
        {wheels}
      </div>
    );
  });

  const axesListTrailer = trailerAxes.map((axis, axisIndex) => {
    const wheels = axis.wheels.map((offset) => {
      wheelIndex++;
      const wIndex = wheelIndex;
      const item = getWheelByIndex(wIndex);

      const classes = item.classes;
      if (axis.mini) classes.push("wheel--mini");

      return (
        <div
          className="wheel-container"
          style={{ transform: `translate(0, ${offset}px)` }}
          key={wIndex}
        >
          <div className={classes.join(" ")}>
            {/* [{wIndex}] */}
            {item.content}
          </div>
        </div>
      );
    });

    return (
      <div className="axis" key={axisIndex} style={{ transform: `translate(${axis.offset}px, 0)` }}>
        {wheels}
      </div>
    );
  });

  // уменьшать ли масштаб?
  let scale = "scale(1)";
  let totalWheels = models[modelId].wheelCount;
  if (trailerId) totalWheels += trailers[trailerId].wheelCount;

  if (totalWheels >= 12) scale = "scale(0.95)";
  if (totalWheels >= 16) scale = "scale(0.85)";
  if (totalWheels >= 20) scale = "scale(0.79)";

  return (
    <div>
      {isPreview && (
        <div className="edit-model__total" style={{ marginTop: "44px" }}>
          Расставлено датчиков:{" "}
          <strong>
            {positions.length} из {sensors?.length}
          </strong>
        </div>
      )}

      <div className="view-model__container" style={scale ? { transform: scale } : {}}>
        <div
          className="view-model"
          style={{
            padding: `${models[modelId].globalPadding}px 0`,
          }}
        >
          {isFlat ? models[modelId].flat : models[modelId].color}

          <div className="wheels">{axesListModel}</div>

          {/* <div className="center"></div> */}
        </div>

        {trailerId && (
          <div
            className="view-model view-model--trailer"
            style={{
              padding: `${trailers[trailerId].globalPadding}px 0`,
            }}
          >
            {isFlat ? trailers[trailerId].flat : trailers[trailerId].color}

            <div className="wheels">{axesListTrailer}</div>

            {/* <div className="center"></div> */}
          </div>
        )}
      </div>
    </div>
  );
};

export default SensorsWModel;
