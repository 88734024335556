import EventColumn from "components/events/EventColumn";
import Violation from "components/violation/Violation";
import { mToKm } from "helpers";
import moment from "moment";
import { PointMoveType } from "../Point";

const PointMove = (stop: PointMoveType) => {
  const handlerClick = (e: any) => {
    const parent1 = e.target.parentNode;
    const parent2 = parent1.parentNode;
    const parent3 = parent2.parentNode;
    const arr = parent3.getElementsByClassName("stop_circle");
    const el1 = e.target;

    Array.from(arr).forEach((element: any) => {
      if (element == el1) {
        el1.classList.toggle("stop_circle--hover");
      } else element.classList.remove("stop_circle--hover");
    });
  };

  return (
    <div className="stop">
      <div className="stop_pic">
        <div className="stop_line"></div>
        <div className="stop_circle" onClick={(e) => handlerClick(e)}></div>
      </div>
      <div className="stop_box stop_box--clean">
        <div
          className={
            stop.events && stop.events.length > 0
              ? "report_data report_data-border report_data--single"
              : "report_data report_data--single"
          }
        >
          <div className="report_data-col ">
            <div className="report_title report_info-title">
              В движении{" "}
              <span className="stop__span">
                с {moment(stop.data.time_start).format("HH:mm")} по{" "}
                {moment(stop.data.time_finish).format("HH:mm")}
              </span>
            </div>
            <div className="report_info report_info-three">
              <EventColumn
                title="начало пробега"
                data={mToKm(stop.data.run_start)}
                smallTitle=" (КМ)"
              />
              <EventColumn
                title="конец пробега"
                data={mToKm(stop.data.run_finish)}
                smallTitle=" (КМ)"
              />
              <EventColumn title="итого" data={mToKm(stop.data.run_total)} smallTitle=" (КМ)" />
            </div>
          </div>
        </div>

        {stop.events &&
          stop.events.length > 0 &&
          stop.events.map((ev: any, index: number) => (
            <div key={index} className="point_violation">
              <Violation event={ev} showDate={false} />
            </div>
          ))}
      </div>
    </div>
  );
};

export default PointMove;
